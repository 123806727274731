/* App.css */


/* Global Styles */
body {
  font-family: 'Sawarabi Gothic', sans-serif;
  font-style: italic;
  font-weight:bold;
  margin: 0;
  padding: 0;
}

.book1 {
  background-image:  url('../public/book1wall.jpg');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding-bottom: 4rem;
}

/* Header Styles */
.header1 {
  background-color: rgba(44, 62, 80, 0.0);
  color: #fff;
  padding: 40px 0;
  text-align: center;
}

.header-content1 {
  max-width: 900px;
  margin: 0 auto;
  position: relative;
}

.header1 h1 {
  margin: 0;
  font-size: 36px;
}

.header1 nav ul {
  list-style: none;
  padding: 0;
}

.header1 nav ul li {
  display: inline-block;
  margin-right: 20px;
}

.header1 nav ul li a {
  color: #fff;
  text-decoration: none;
}

/* Book Info Styles */
.book-info1 {
  padding: 0 20px;
  text-align: center;
}

.book-info1 p {
  font-size: 1.2rem;
  font-weight: 400;
  padding: 10px 0;
  color: #2f2f2f;
}

.book-info1 h2 {
  color: #fff;
  margin-top: 50px;
}

.book-content1 {
  display: flex;
  justify-content: space-between;
  align-items: right;
  flex-wrap: wrap; 
  margin: 0 auto;
}

.button1{
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button1 a{
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
}

.button1:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

@media (min-width: 768px) {
  .button1 {
    padding: 0.25em 0.75em;
  }
}

.book-details1 {
  width: 50%; 
}

.book-cover-container1 {
  width: 50%; 
}

.book-cover-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-cover1 {
  max-width: 55%;
  height: auto;
  box-shadow: 0 16px 32px rgba(0,0,0,0.8);
  /* Add more styles like border-radius, box-shadow, etc., to enhance the appearance */
}

.book2{
  padding-top: 3rem;
  background-image:  url('../public/book2wall.png');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding-bottom: 4rem;
}

/* Header Styles */
.header2 {
  background-color: rgba(44, 62, 80, 0.0);
  color: #fff;
  padding: 40px 0;
  text-align: center;
}

.header-content2 {
  max-width: 900px;
  margin: 0 auto;
  position: relative;
}

.header2 h1 {
  margin: 0;
  font-size: 36px;
}

.header2 nav ul {
  list-style: none;
  padding: 0;
}

.header2 nav ul li {
  display: inline-block;
  margin-right: 20px;
}

.header2 nav ul li a {
  color: #fff;
  text-decoration: none;
}

/* Book Info Styles */
.book-info2 {
  padding: 0 20px;
  text-align: center;
}

.book-info2 p {
  font-size: 1.2rem;
  font-weight: 400;
  padding: 10px 0;
  color: #fff;
}

.book-info2 h2 {
  color: #fff;
  margin-top: 50px;
}

.book-content2 {
  display: flex;
  justify-content: space-between;
  align-items: right;
  flex-wrap: wrap; 
  margin: 0 auto;
}

.button2{
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button2 a{
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
}

.button2:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

@media (min-width: 768px) {
  .button2 {
    padding: 0.25em 0.75em;
  }
}

.book-details2 {
  width: 50%; 
}

.book-cover-container2 {
  width: 50%; 
}

.book-cover-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-cover2 {
  max-width: 55%;
  height: auto;
  box-shadow: 0 16px 32px rgba(0,0,0,0.8);
  /* Add more styles like border-radius, box-shadow, etc., to enhance the appearance */
}

/* Footer Styles */
.footer {
  background-color: rgba(0,0,0, 0);
  color: #fff;
  text-align: left;
  font-size: 1.2rem;
  margin-top: 4rem;
  margin-left: 40px;
}
